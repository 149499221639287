import React from 'react'
import { BarracudaApiClient } from '../classes/BarracudaApiClient'
import * as Hooks from 'usehooks-ts'

export const useBalance = (barracuda: BarracudaApiClient, delay = 500) => {
  const [balance, setBalance] = React.useState(0n)
  const [update, setUpdate] = React.useState<'positive' | 'negative' | null>(null)

  Hooks.useEffectOnce(() => {
    barracuda
      .getUserBalance()
      .then(setBalance)
      .catch((e) => console.warn('Failed to fetch balance', e))

    let handle: NodeJS.Timeout

    const removeListener = barracuda.onBalanceUpdate((newBalance, change) => {
      const update = change === 0n ? null : change > 0n ? 'positive' : 'negative'

      setUpdate(update)
      setBalance(newBalance)

      handle = setTimeout(() => {
        setUpdate(null)
      }, delay)
    })

    return () => {
      removeListener()
      if (handle) clearTimeout(handle)
    }
  })

  return { balance, update }
}
