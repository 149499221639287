import React, { PropsWithChildren } from 'react'
import { CasinoApiClient } from '../classes/CasinoApiClient'
import { BarracudaApiClient } from '../classes/BarracudaApiClient'
import CoinFlipOption from './CoinFlipOption'
import { GREEN } from '../constants'
import BetAmountSelector from './BetAmountSelector'
import { config } from '../config'

export interface CoinFlipProps extends PropsWithChildren {
  casino: CasinoApiClient
  barracuda: BarracudaApiClient
}

export type GameStatus = 'idle' | 'pending' | 'completed'

export type GameChoice = 'heads' | 'tails' | 'none'

const CoinFlip: React.FC<CoinFlipProps> = (props) => {
  const [status, setStatus] = React.useState<GameStatus>('idle')
  const [choice, setChoice] = React.useState<GameChoice>('none')
  const [result, setResult] = React.useState<GameChoice>('none')
  const [betAmount, setBetAmount] = React.useState(5n)

  const playHandler = async (choice: 'heads' | 'tails') => {
    if (!['heads', 'tails'].includes(choice)) {
      throw new Error('Invalid choice')
    }

    setStatus('pending')

    const roundId = await props.casino
      .play({
        category: 'coin_flip',
        betAmount: betAmount * BigInt('10') ** BigInt(config.usdtDecimals.toString()),
        choice: choice as any,
      })
      .catch((err) => {
        setStatus('idle')
        setChoice('none')

        console.error(err)
        alert('Round failed')

        return null
      })

    if (!roundId) return

    const removeListener = props.casino.onGameFinished((_roundId: string, _result: string) => {
      if (!roundId || roundId !== _roundId) return

      setResult(_result as any)
      setStatus('completed')

      removeListener()

      setTimeout(
        () => {
          resetHandler()
        },
        _result === choice ? 1500 : 1000,
      )
    })
  }

  const resetHandler = () => {
    setStatus('idle')
    setChoice('none')
    setResult('none')
  }

  const choiceHandler = (choice: 'heads' | 'tails') => {
    setChoice(choice)
    playHandler(choice)
  }

  return (
    <div>
      <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
        <CoinFlipOption
          onClick={() => choiceHandler('heads')}
          roundStatus={status}
          choice={choice}
          result={result}
          type="heads"
        />
        <CoinFlipOption
          onClick={() => choiceHandler('tails')}
          roundStatus={status}
          choice={choice}
          result={result}
          type="tails"
        />

        <div style={{ marginLeft: 30 }}>
          {status === 'idle' && <div style={styles.faded}>Choose heads or tails ...</div>}
          {status === 'pending' && <div style={styles.faded}>Wait ...</div>}
          {status === 'completed' && choice === result && (
            <h3 style={styles.flashyWin}>YOU WON !!!</h3>
          )}
          {status === 'completed' && choice !== result && <h3 style={styles.faded}>You lost.</h3>}
        </div>
      </div>
      <br />
      <br />
      <BetAmountSelector
        current={betAmount}
        onSelect={setBetAmount}
        options={[1n, 2n, 5n, 10n, 20n, 50n, 100n, 200n, 500n, 10000n, 20000n, 50000n]}
      />
    </div>
  )
}

const styles: Record<string, React.CSSProperties> = {
  flashyWin: {
    animation: 'flash 0.06s ease-in-out infinite alternate',
    color: GREEN,
  },
  faded: {
    color: '#aaa',
  },
}

export default CoinFlip
