import React, { PropsWithChildren } from 'react'
import { BLUE, GREEN, RED, WHITE } from '../constants'

export interface CoinFlipOptionProps extends PropsWithChildren {
  onClick: () => void
  roundStatus: 'idle' | 'pending' | 'completed'
  choice: 'tails' | 'heads' | 'none'
  result: 'tails' | 'heads' | 'none'
  type: 'heads' | 'tails'
}

const CoinFlipOption: React.FC<CoinFlipOptionProps> = (props) => {
  const color = (() => {
    if (['idle', 'pending'].includes(props.roundStatus)) {
      if (props.choice === props.type) return BLUE
      else return WHITE
    }

    if (props.roundStatus === 'completed') {
      if (props.type === props.choice) {
        return props.choice === props.result ? GREEN : RED
      } else {
        return WHITE
      }
    }

    return 'pink'
  })()

  return (
    <div
      style={{
        fontSize: 75,
        border: `solid ${color} 6px`,
        color,
        borderRadius: '50%',
        width: 100,
        height: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: props.roundStatus === 'idle' && props.choice !== props.type ? 'pointer' : 'default',
        fontWeight: 700,
        transition: 'all 0.2s',
        animation:
          props.roundStatus === 'pending'
            ? 'flip 0.075s ease-in-out infinite alternate'
            : undefined,
      }}
      onClick={() => {
        if (props.roundStatus !== 'idle') return

        props.onClick()
      }}
      className="clickable"
    >
      {(props.type ?? 'x').at(0)?.toUpperCase()}
    </div>
  )
}

export default CoinFlipOption
