import Axios, { AxiosInstance } from 'axios'
import { io, Socket } from 'socket.io-client'

export interface BarracudaApiClientOptions {
  baseUrl: string
  websocketUrl: string
}

export interface WebsocketEvent {
  address: string
  type: string
  data: Record<string, any>
}

export class BarracudaApiClient {
  private axios: AxiosInstance
  private socket: Socket

  constructor(opts: BarracudaApiClientOptions) {
    this.axios = Axios.create({ baseURL: `${opts.baseUrl}/v1`, withCredentials: true })

    this.socket = io(opts.websocketUrl, {
      path: '/ws',
      withCredentials: true,
      transports: ['websocket'],
      autoConnect: false,
    })

    this.socket.onAny(console.log)
  }

  connect() {
    if (this.socket.connected) return

    this.socket.connect()
  }

  disconnect() {
    if (!this.socket.connected) return

    this.socket.disconnect()
  }

  reconnect() {
    this.disconnect()
    this.connect()
  }

  onBalanceUpdate(func: (b: bigint, change: bigint) => void) {
    const eventName = 'balance_update'

    const listener = (event: WebsocketEvent) =>
      func(BigInt(event.data.newBalance), BigInt(event.data.change))

    this.socket.on(eventName, listener)

    return () => {
      this.socket.removeListener(eventName, listener)
    }
  }

  async getCurrentUser() {
    return await this.axios.get('/auth/user').then((res) => res.data)
  }

  async getUserBalance() {
    return await this.getCurrentUser().then((user) => BigInt(user.wallet.balance))
  }
}
