import React from 'react'
import { BLUE } from '../constants'

export interface BetAmountSelectorProps {
  onSelect: (option: bigint) => void
  current: bigint
  options: bigint[]
}

const BetAmountSelector: React.FC<BetAmountSelectorProps> = (props) => {
  const WHITE = '#ccc'

  return (
    <div style={{ maxWidth: 500 }}>
      {props.options.map((option) => (
        <div
          key={option.toString()}
          style={{
            display: 'inline-flex',
            padding: 10,
            marginTop: 12,
            marginRight: 12,
            border: `solid ${props.current === option ? BLUE : WHITE} 3px`,
            borderRadius: 5,
            width: 40,
            alignItems: 'center',
            justifyContent: 'center',
            color: props.current === option ? BLUE : WHITE,
            cursor: 'pointer',
            transition: 'all 0.2s',
          }}
          onClick={() => props.onSelect(option)}
          className="clickable"
        >
          ${option.toString()}
        </div>
      ))}
    </div>
  )
}

export default BetAmountSelector
