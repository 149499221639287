import '@barracuda-widgets/barracuda-user-widget/dist/style.css'

import React from 'react'
import { UserWidget } from '@barracuda-widgets/barracuda-user-widget'
import { useBalance } from '../hooks/useBalance'
import { GREEN, RED } from '../constants'
import { config } from '../config'
import { BarracudaApiClient } from '../classes/BarracudaApiClient'

export interface TopBarProps {
  barracuda: BarracudaApiClient
}

const TopBar: React.FC<TopBarProps> = (props) => {
  const { balance, update } = useBalance(props.barracuda, 500)

  const color = (() => {
    if (update === 'positive') return GREEN
    if (update === 'negative') return RED

    return '#eee'
  })()

  const balanceString = Math.floor(Number(balance) / 10 ** config.usdtDecimals)

  return (
    <div
      style={{
        backgroundColor: 'black',
        height: 100,
        width: 'calc(100% - 40px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 20px',
        color,
        animation: update
          ? `${update === 'positive' ? 'green' : 'red'}-to-white 0.5s ease-out`
          : undefined,
      }}
    >
      <h2>$ {balanceString}</h2>
      <h2 style={{ color: window.location.hostname === 'localhost' ? RED : GREEN }}>
        {window.location.hostname}
      </h2>
      <UserWidget />
    </div>
  )
}

export default TopBar
