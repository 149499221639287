import '@barracuda-widgets/stats-widget/dist/style.css'

import React from 'react'
import { StatsWidget } from '@barracuda-widgets/stats-widget'

const Stats = () => {
  return <StatsWidget />
}

export default Stats
