import React, { PropsWithChildren } from 'react'

export interface LayoutProps extends PropsWithChildren {
  topBar: React.ReactNode
}

const Layout: React.FC<LayoutProps> = (props) => {
  return (
    <div style={{ width: '100%', height: '100%' }}>
      {props.topBar}
      <div
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          height: 'calc(100% - 100px)',
        }}
      >
        {props.children}
      </div>
    </div>
  )
}

export default Layout
