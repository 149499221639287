import Axios, { AxiosInstance } from 'axios'
import { io, Socket } from 'socket.io-client'

export interface CasinoApiClientOptions {
  baseUrl: string
  websocketUrl: string
}

export interface CoinFlipGameInput {
  category: 'coin_flip'
  betAmount: bigint
  choice: 'heads' | 'tails'
}

export interface UserData {
  address: string
  balance: bigint
}

export type GameInput = CoinFlipGameInput

export class CasinoApiClient {
  private axios: AxiosInstance
  private socket: Socket

  constructor(opts: CasinoApiClientOptions) {
    this.axios = Axios.create({ baseURL: `${opts.baseUrl}/api/v1`, withCredentials: true })

    this.socket = io(opts.websocketUrl, {
      path: '/ws',
      withCredentials: true,
      transports: ['websocket'],
      autoConnect: false,
    })

    this.socket.onAny(console.log)
  }

  connect() {
    if (this.socket.connected) return

    this.socket.connect()
  }

  disconnect() {
    if (!this.socket.connected) return

    this.socket.disconnect()
  }

  reconnect() {
    this.disconnect()
    this.connect()
  }

  /**
   * Start game and return id
   */
  async play(input: GameInput): Promise<string> {
    if (input.category === 'coin_flip') {
      return await this.playCoinFlip(input)
    }

    throw new Error('Invalid game category')
  }

  onGameFinished(func: (roundId: string, result: string) => void) {
    const eventName = 'games.finished'

    const listener = (event: any) => {
      const round = event.data.gameRound
      func(round.id, round.gameState.result)
    }

    this.socket.on(eventName, listener)

    return () => {
      this.socket.removeListener(eventName, listener)
    }
  }

  /**
   * Get current user data
   */
  async getCurrentUser(): Promise<UserData> {
    const data = await this.axios.get('/auth/user').then((res) => res.data)

    return {
      address: data.address,
      balance: BigInt(data.balance),
    }
  }

  /**
   * Get list of games
   */
  async getCategories(): Promise<string[]> {
    return await this.axios
      .get('/games/categories')
      .then((res) => res.data.map((cat: any) => cat.slug))
  }

  private async playCoinFlip(input: CoinFlipGameInput): Promise<string> {
    return await this.axios
      .post(`/games/categories/${input.category}/play`, {
        category: input.category,
        betAmount: input.betAmount.toString(),
        data: { choice: input.choice },
      })
      .then((res) => res.data.id)
  }
}
