import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'

import { injectBarracuda } from 'barracuda-provider'

import './index.css'

import { config } from './config'
;(window as any).config = config

console.log('CONFIG', config)

await injectBarracuda({
  apiUrl: process.env.REACT_APP_BARRACUDA_API_URL!,
  apiWsUrl: process.env.REACT_APP_BARRACUDA_API_WS_URL!,
}).catch((e) => {
  console.error('Failed to initialize Barracuda: ', e)
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)
