export const config = Object.freeze({
  chainId: process.env.REACT_APP_CHAIN_ID!,
  barracudaApiDomain: process.env.REACT_APP_BARRACUDA_API_DOMAIN!,
  barracudaApiUrl: process.env.REACT_APP_BARRACUDA_API_URL!,
  barracudaApiWsUrl: process.env.REACT_APP_BARRACUDA_API_WS_URL!,
  usdtContractAddress: process.env.REACT_APP_USDT_CONTRACT_ADDRESS!,
  barrTokenContractAddress: process.env.REACT_APP_BARR_TOKEN_CONTRACT_ADDRESS!,
  casinoAuthServiceName: process.env.REACT_APP_CASINO_AUTH_SERVICE_NAME!,
  casinoContractAddress: process.env.REACT_APP_CASINO_CONTRACT_ADDRESS!,
  casinoApiUrl: process.env.REACT_APP_CASINO_API_URL!,
  casinoApiWsUrl: process.env.REACT_APP_CASINO_API_WS_URL!,
  usdtDecimals: Number(process.env.REACT_APP_USDT_DECIMALS ?? '6'),
})
