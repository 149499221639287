import React from 'react'
import { BarracudaUser } from 'barracuda-provider'

import Layout from './components/Layout'
import TopBar from './components/TopBar'
import CoinFlip from './components/CoinFlip'
import { CasinoApiClient } from './classes/CasinoApiClient'
import { BarracudaApiClient } from './classes/BarracudaApiClient'
import { config } from './config'
import Stats from './components/Stats'

const casino = new CasinoApiClient({
  baseUrl: config.casinoApiUrl,
  websocketUrl: config.casinoApiWsUrl,
})

const barracuda = new BarracudaApiClient({
  baseUrl: config.barracudaApiUrl,
  websocketUrl: config.barracudaApiWsUrl,
})

const App = () => {
  const [userData, setUserData] = React.useState<BarracudaUser>()

  React.useEffect(() => {
    if (!window.Barracuda) return

    window.Barracuda.onUpdate((status) => setUserData(status.user))

    return () => {
      window.Barracuda.offUpdate((status) => setUserData(status.user))
    }
  }, [])

  // Reconnect websockets in both Casino Client and Barracuda Client when user address changes
  React.useEffect(() => {
    if (!userData?.address) {
      casino.disconnect()
      barracuda.disconnect()
      return
    }

    casino.connect()
    barracuda.connect()

    return () => {
      casino.disconnect()
      barracuda.disconnect()
    }
  }, [userData?.address])

  return (
    <Layout topBar={<TopBar barracuda={barracuda} />}>
      <div>
        <br />
        <br />
        <br />
        <h1>Dis ain't da ca$ino u l&#128064;kin 4...</h1>
        <img src="https://media.tenor.com/jALHKEFxe44AAAAC/star-wars-master-yoda.gif" alt="gif" />
        <br />
        <br />

        <h2>Coin Flip:</h2>
        <br />
        <br />
        <CoinFlip barracuda={barracuda} casino={casino} />
        <br />
        <Stats />
      </div>
    </Layout>
  )
}

export default App
